@import "@tvg/ui-bootstrap/components/Less/variables.css";
@import "../../variables.css";

@media (--xs-view), (--l-view) {
  .myAccountButton {
    width: 54px;

    & > .accountLabel {
      & span {
        display: none;
      }

      & i:last-child {
        margin-left: -15px;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  @media (--xs-view),
    (--s-view),
    (--m-view),
    (--l-view),
    (--xl-view),
    (--xxl-view) {
    .myAccountButton > .accountLabel {
      left: -15px;
    }
  }
}

.activeButton {
  background: rgba(255, 255, 255, 0.1) !important;

  & svg {
    fill: var(--tvg-white) !important;
  }

  &:hover {
    & span {
      visibility: hidden !important;
    }
  }
}

.myAccountButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
  height: 48px;
  margin-right: 8px;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: rgba(255, 255, 255, 0.1);

    & svg {
      fill: var(--tvg-white);
    }
  }

  & > .accountLabel {
    margin-right: 0px;
    padding: 0 10px 0 40px;
    background-color: transparent;
    border: none;
    outline: none;
    line-height: 48px;
    color: var(--tvg-white);
    position: relative;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    height: 100%;
    width: 100%;

    & i:first-child {
      background--color: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      position: absolute;
      left: 8px;
      top: 12px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }

    & i:last-child {
      margin-left: 5px;
    }
  }

  /* Fix for when newHeaderExperience toggled off */
  & > .overlayArea {
    top: 55px;
  }
}

.showOverlay {
  display: block;
  opacity: 1;
  top: calc(var(--header-height) - 6px);
  animation: moveUp 0.3s cubic-bezier(0, 1.5, 0.6, 1);
  transition:
    opacity 0.3s cubic-bezier(0, 1.5, 0.6, 1),
    top 0s linear;
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.1),
    0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.hideOverlay {
  opacity: 0;
  top: -9999px;
  animation: moveDown 0.1s cubic-bezier(0, 1.5, 0.6, 1);
  transition:
    opacity 0.1s cubic-bezier(0, 1.5, 0.6, 1),
    top 0s linear;
}

.myAccountIconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 6px;
  height: var(--header-height);
  cursor: pointer;
  background: transparent;
  border: none;

  & > .accountIcon {
    display: flex;
    align-items: center;
    padding: 5px 0;
    background-color: transparent;
    border: none;
    outline: none;
    position: relative;
    height: 100%;
    width: 100%;

    & span {
      visibility: hidden;
      position: absolute;
      top: calc(var(--header-height) - 8px);
      z-index: 99;
      height: 30px;
      padding: 8px;
      color: var(--tvg-black);
      background: var(--tvg-white);
      box-shadow:
        0px 4px 4px rgba(0, 0, 0, 0.1),
        0px 4px 6px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size-normal);
      line-height: 17px;
      overflow: hidden;
      white-space: nowrap;
    }

    & i {
      border-radius: 50%;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      &:hover,
      &:focus {
        background-color: rgba(255, 255, 255, 0.1);

        & span {
          visibility: visible;
        }
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.userOutlineIcon {
  margin-top: 2px;
  margin-right: -4px;
}
