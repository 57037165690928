@import "@tvg/ui-bootstrap/components/Less/variables.css";
@import "../../variables.css";

.menuItemHeader {
  display: none;
}

@media (--xl-view), (--xxl-view) {
  .menuItemHeader {
    display: flex;
    align-items: initial;
    justify-content: initial;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1 1 400px;
    height: var(--header-height);

    & .menuItemListHeader {
      display: flex;
      align-items: flex-start;
      justify-content: initial;
      flex-direction: row;
      flex: 1 1 400px;
      margin: 0 24px;
    }
  }
}

.menuItemNav {
  z-index: 10;
  display: none;
}

@media (--s-view), (--xs-view), (--m-view), (--l-view) {
  .menuItemNav {
    display: flex;
    width: var(--side-menu-width);
    height: calc(100vh - 50px);
    overflow-y: auto;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #1e4b79;
    padding: 20px 16px;
    transition: left 0.4s;
    transition-timing-function: linear;
  }
}

@media (--s-view), (--xs-view), (--m-view), (--l-view) {
  .collapsed .menuItemNav {
    left: -999px;
    margin-top: var(--header-height);
  }
}
@media (--s-view), (--xs-view), (--m-view), (--l-view), (--xl-view) {
  .overlay.opened {
    height: 100vh;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    top: var(--header-height);
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
}

.opened {
  & .menuItemNav {
    left: 0;
    line-height: 1;
    top: var(--header-height);
  }
}

@media (width: 768px) {
  .opened {
    & .menuItemNav {
      height: 1320px;
    }
  }
}
