@import "@tvg/ui-bootstrap/components/Less/variables.css";
@import "../../../variables.css";

.links {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  background: var(--tvg-white);
  color: #384048;
}

.category {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  font-family: var(--roboto-font-bold);
  padding: 12px;
  font-size: 16px;
  font-weight: 700;
}

.linkItem {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  font-family: var(--roboto-font-regular);
  line-height: 18px;
  padding: 14px 0px 14px 12px;

  &:not(:last-child) {
    box-shadow:
      inset 12px 0px 0px var(--tvg-white),
      inset 0px -1px 0px #d6e3f0;
  }

  &:hover {
    background: #fafcff;
  }

  &[role="link"]:hover {
    cursor: pointer;
  }

  &:active {
    background: #ebf5ff;
  }

  & span {
    padding-left: 14px;
    color: #384048;
  }
}

.newTagWrapper {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}
