@import '@tvg/ui-bootstrap/components/Less/variables.css';

.signupBlock {
  width: 100%;
  height: auto;
  background-image: url('./svg/signup_bg_img.jpg');
  background-color: var(--tvg-blue-darkest);
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  & .signupBlockContainer {
    padding: 16px;
    z-index: 6;

    @media screen and (max-width: 750px) and (orientation: landscape) {
      display: flex;
      padding: 0 16px;
      flex-wrap: wrap;
      flex-grow: 2;
      justify-content: space-between;
    }
  }

  & .bglayer {
    position: absolute;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(28, 50, 71, 0), var(--tvg-blue-darkest) 22%);
    height: 25%;
    z-index: 5;
    bottom: 0;
  }
}

.signupBlockMobile {
  background-size: cover;
}
