@import "variables.css";

:root {
  --font-size-h1: 2.5rem;
  --font-size-h2: 2rem;
  --font-size-h3: 1.75rem;
  --font-size-h4: 1.5rem;
  --font-size-h5: 1.25rem;
  --font-size-h6: 1rem;
}

body {
  font-family: var(--font-family-default);
}

.tvg-h1,
.tvg-h2,
.tvg-h3,
.tvg-h4,
.tvg-h5,
.tvg-h6 {
  font-family: var(--font-family-titling);
  color: var(--color-secondary);
}

.tvg-h1 {
  font-size: var(--font-size-h1);
}

.tvg-h2 {
  font-size: var(--font-size-h2);
}

.tvg-h3 {
  font-size: var(--font-size-h3);
}

.tvg-h4 {
  font-size: var(--font-size-h4);
}

.tvg-h5 {
  font-size: var(--font-size-h5);
}

.tvg-h6 {
  font-size: var(--font-size-h6);
}
