@import "@tvg/ui-bootstrap/components/Less/typography.css";
@import "../../variables.css";

@media only screen and (max-width: 1060px) {
  .watchLive {
    margin-left: auto;
  }
}

@media (--xs-view) {
  .watchLive {
    & span {
      display: none;
    }
  }
}

@media (--l-view) {
  .watchLive {
    & span {
      display: none;
    }
  }
}

.watchLive {
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  &:before,
  &:link,
  &:visited {
    content: "";
    width: 24px;
    height: 24px;
    background: var(--tvg-white-ghost);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: 8px;
    top: 12px;
  }

  & a {
    padding: 0 8px 0 16px;
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--tvg-white);
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: initial;
    flex-direction: row;
    white-space: nowrap;

    &:hover,
    &:focus {
      color: var(--tvg-white);
    }

    & svg {
      position: relative;
      left: -4px;
    }
  }

  & .watchTvgLabel {
    line-height: 11px;
    height: 11px;
    overflow: hidden;
    padding-left: 8px;
    color: white;
  }
}

.watchLiveV2 {
  position: relative;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: var(--tvg-white-hdr-btn);
  border-radius: 2px;

  &:hover,
  &:active {
    background: var(--tvg-white-ghost);
  }

  & a {
    padding: 0 12px 0 16px;
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--tvg-white);
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: initial;
    flex-direction: row;
    white-space: nowrap;

    &:hover,
    &:focus {
      color: var(--tvg-white);
    }

    & svg {
      position: relative;
    }
  }

  & .watchTvgLabel {
    line-height: 11px;
    height: 11px;
    overflow: hidden;
    padding-left: 9px;
    color: white;
  }
}
