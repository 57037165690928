@import "@tvg/ui-bootstrap/components/Less/variables.css";
@import "../../../variables.css";

.userSection {
  width: 100%;
  background: var(--tvg-white);
  color: var(--tvg-black);
  border-radius: 4px 4px 0 0;

  & > header {
    border-radius: 4px 4px 0 0;
    box-shadow: inset 0px -1px 0px #d6e3f0;
  }

  & .userEmail,
  & .userAccNr {
    display: block;
    font-size: 12px;
  }

  & .userAccNr {
    margin-bottom: 3px;

    &:before {
      content: "#";
    }
  }

  & .userEmail {
    font-weight: bold;
    text-transform: lowercase;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 160px;
  }
}

.editButton {
  margin-left: auto;
}

.depositWrapper {
  width: 100%;
  display: flex;
  box-shadow:
    0 1px 0 0 var(--tvg-blue-greyish),
    inset 0 1px 0 0 var(--tvg-blue-greyish);
}

.depositsButton {
  min-height: 40px;
  border-radius: 0 !important;
  background: var(--tvg-white);
  border: 0;
  color: #194879;
  font-weight: bold;
  justify-content: center;
  flex: 1;

  &:last-child {
    border-left: 1px solid #dfe7f0;
  }

  &:hover {
    background-color: var(--tvg-blue-greyish);
    transition: background-color 0.3s ease-in-out;
  }
}

.userArea {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 7px 16px;
  box-shadow: inset 0 -1px 0 0 var(--tvg-blue-greyish);
  flex: 0 0 100%;

  & a {
    display: block;

    &:first-child {
      font-weight: normal;
      text-transform: none;
    }
  }
}

.balanceArea {
  overflow: hidden;
  padding: 12px 16px 10px;
  border-bottom: 1px solid var(--tvg-blue-lightest);
}

.balanceLabel {
  line-height: 1.2;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--tvg-grey-dark);
  font-family: var(--font-Univers-CondensedBold);
}

.balance {
  font-family: var(--font-Univers-Condensed);
  font-size: 30px;
  font-weight: bold;
  color: var(--tvg-green);
  max-height: 50px;
  transition:
    opacity 0.3s ease-in-out,
    max-height 0.3s ease-in-out;
  float: left;
  height: 36px;
}

.buttonWithImage {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.centerItems {
    justify-content: center;
  }

  & svg {
    margin-right: 4px;
  }
}

.showHideLink {
  color: var(--tvg-blue-bright);
  text-transform: none;
  width: 150px;
  overflow: hidden;
  margin-top: 5px;
  float: right;
  font-size: 12px;
  background-color: transparent;
  border: 0;
  opacity: 1;
  display: block;
  text-align: right;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  animation: fadeIn 0.3s ease-in-out 0s;
  padding: 0;

  &.invisible {
    display: none;
    opacity: 0;
    animation: fadeOut 0.3s ease-in-out 0s;
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
}
