@import "../Less/variables.css";

.modal {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);

  @media (width: 768px) {
    min-height: 100%;
    min-width: 100%;
  }
}

.fullscreen {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 100%;
  left: 50%;
  background-color: var(--tvg-white);
  transform: translate3d(-50%, 100%, 0);
  overflow: scroll;
}

.scrollContent {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: var(--tvg-white);
  transform: translate3d(-50%, -50%, 0);
  transition: all 300ms ease-in-out;
  width: 80%;
  max-width: 1000px;
  height: 80%;
  margin-left: 115px;

  @media (max-width: 767px) {
    margin-left: 0;
    height: 100%;
    width: 100%;
  }
}

.regular {
  position: absolute;
  max-width: 80%;
  top: 50%;
  left: 50%;
  background-color: var(--tvg-white);
  overflow: scroll;
  -ms-overflow-style: none;
}

.presentation {
  position: absolute;
  min-height: calc(100% - 48px);
  max-height: calc(100% - 48px);
  top: 100%;
  left: 50%;
  background-color: var(--tvg-white);
}

.enter {
  animation: overlay-animation-enter 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

  & .regular {
    opacity: 0;
    animation: regular-animation-enter 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) 0.1s;
    animation-fill-mode: forwards;
  }

  & .presentation,
  & .fullscreen {
    animation: presentation-animation-enter 0.4s cubic-bezier(0.25, 0.1, 0.25, 1) 0.1s;
    animation-fill-mode: forwards;
  }
}

.leave {
  animation: overlay-animation-leave 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  animation-fill-mode: forwards;

  & .regular {
    animation: regular-animation-leave 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    animation-fill-mode: forwards;
  }

  & .presentation,
  & .fullscreen {
    animation: presentation-animation-leave 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    animation-fill-mode: forwards;
  }
}

@keyframes overlay-animation-enter {
  from {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0);
  }

  to {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
  }
}

@keyframes overlay-animation-leave {
  from {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
  }

  to {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0);
  }
}

@keyframes regular-animation-enter {
  from {
    opacity: 0;
    transform: translate(-50%, -40%);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes regular-animation-leave {
  from {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-50%, -40%, 0);
  }
}

@keyframes presentation-animation-enter {
  from {
    transform: translate3d(-50%, 0, 0);
  }

  to {
    transform: translate3d(-50%, -100%, 0);
  }
}

@keyframes presentation-animation-leave {
  from {
    transform: translate3d(-50%, -100%, 0);
  }

  to {
    transform: translate3d(-50%, 0, 0);
  }
}
