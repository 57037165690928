@import "@tvg/ui-bootstrap/components/Less/variables.css";
@import "../../variables.css";

:root {
  --subMenuWidth: 200px;
  --menuItemHeight: var(--header-height);
  --menuItemLateralPadding: 16px;
  --menuItemLargeLateralPadding: 16px;
}

.menuItem {
  white-space: nowrap;
  outline: 0;
}
.newMenuLabel {
  position: absolute;
  left: 0;
  padding-left: var(--menuItemLateralPadding);
  top: 5px;
  font-size: 10px;
  color: var(--tvg-yellow);
  line-height: 1.3333;
}

@media (--xs-view), (--s-view), (--m-view), (--l-view), (--xl-view) {
  .newMenuLabel {
    display: inline-block;
    position: relative;
    font-size: 11px;
    font-weight: bold;
    color: var(--text-grey-black);
    padding: 2px;
    background: var(--tvg-yellow);
    border-radius: 2px;
    text-transform: uppercase;
    margin-left: 8px;
    bottom: 2px;
    top: auto;
  }

  .menuItem {
    &:not(:first-child) {
      margin-top: 20px;
    }

    &:last-child {
      padding-bottom: 20px;
    }

    & > a > i {
      display: none;
    }

    & > .menuItemLink {
      color: var(--tvg-blue-light);
      margin-bottom: 16px;
      font-weight: 500;
      display: inline-block;
      position: relative;
      text-transform: uppercase;
      font-family: var(--roboto-title-font);
      letter-spacing: -1.3 !important;
      margin-left: 12px;
    }

    & .subMenuList > li > a {
      font-size: 12px;
      font-weght: bold;
      text-transform: uppercase;
      color: var(--tvg-blue);
      margin-bottom: 30px;
      display: block;
    }

    & .subMenuList > li:last-child > a {
      margin-bottom: 34px;
    }
  }

  .menuItem.sideMenuButton {
    padding: 0;

    & > a {
      display: block;
      margin: 0;
      padding: 12px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 4px;

        & svg {
          fill: var(--tvg-white);
        }

        & span {
          color: var(--tvg-white);
        }
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 4px;
      }

      & > i {
        display: flex;
        color: #d6e3f0;

        & > span {
          margin-left: 12px;
          font-family: var(--roboto-font-medium);
          text-transform: none;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}

@media (--xxl-view), (--xl-view) {
  .menuItem {
    box-sizing: border-box;
    color: #d6e3f0;
    cursor: pointer;
    height: var(--menuItemHeight);
    position: relative;
    padding: 0 var(--menuItemLateralPadding);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;

    & .menuItemLink {
      line-height: 12px;
      color: #d6e3f0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      &:focus,
      &:active {
        outline: none;
        color: var(--tvg-white);
      }

      &:hover {
        color: var(--tvg-white);
      }

      & i {
        margin-left: 5px;
      }

      & .arrowUp {
        display: none;
      }
    }

    &:hover {
      background-color: rgb(255, 255, 255 / 0.1);
      color: var(--tvg-white);

      & .arrowDown {
        display: none;
      }

      & .arrowUp {
        display: inline-block;
      }

      & .subMenuList {
        display: block;
        opacity: 1;
        top: calc(var(--header-height) - 20px);
        animation: moveUp 0.3s cubic-bezier(0, 1.5, 0.6, 1);
        transition:
          opacity 0.3s cubic-bezier(0, 1.5, 0.6, 1),
          top 0s linear;
      }
    }

    & .subMenuList {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      opacity: 0;
      top: -9999px;
      animation: moveDown 0.1s cubic-bezier(0, 1.5, 0.6, 1);
      transition:
        opacity 0.1s cubic-bezier(0, 1.5, 0.6, 1),
        top 0s linear;
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);

      & .clearSubMenu {
        display: none;
      }
    }
  }
}

@media (--xxl-view) {
  .newMenuLabel {
    padding-left: var(--menuItemLargeLateralPadding);
  }
  .menuItem {
    padding: 0 var(--menuItemLargeLateralPadding);
  }
}

.menuItemHeader {
  box-sizing: border-box;
  color: #d6e3f0;
  cursor: pointer;
  height: calc(var(--menuItemHeight) - 26px);
  position: relative;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-family: var(--roboto-font-medium);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  white-space: nowrap;
  outline: 0;
  border-radius: 4px;

  & .menuItemLink {
    padding: 0 var(--menuItemLateralPadding);
    line-height: 12px;
    color: #d6e3f0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    & i {
      margin-left: 5px;
    }

    & .arrowUp {
      display: none;
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 4px;
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);

    & .menuItemLink span {
      color: var(--tvg-white);
    }

    & .arrowDown {
      display: none;
    }

    & .arrowUp {
      display: inline-block;
    }

    & .subMenuList {
      display: block;
      opacity: 1;
      top: calc(var(--header-height) - 20px);
      z-index: 100;
      animation: moveUp 0.3s cubic-bezier(0, 1.5, 0.6, 1);
      transition:
        opacity 0.3s cubic-bezier(0, 1.5, 0.6, 1),
        top 0s linear;

      & :before {
        content: "";
        display: block;
        height: 10px;
        position: absolute;
        top: -10px;
        width: 100%;
      }
    }
  }

  & .subMenuList {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0;
    top: -9999px;
    animation: moveDown 0.1s cubic-bezier(0, 1.5, 0.6, 1);
    transition:
      opacity 0.1s cubic-bezier(0, 1.5, 0.6, 1),
      top 0s linear;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);

    & .clearSubMenu {
      display: none;
    }
  }
}
