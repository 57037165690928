@import '@tvg/ui-bootstrap/components/Less/variables.css';

.advantageBlock {
  display: flex;
  width: 268px;
  align-items: center;
  margin-bottom: 16px;

  @media screen and (max-width: 750px) and (orientation: landscape) {
    margin-bottom: 0;
  }

  /* iPhone 5 */
  @media (width: 320px) {
    margin-bottom: 0;
  }

  & .iconAdvantage {
    margin-right: 16px;
  }

  & .textAdvantage {
    color: var(--tvg-white);
    font-family: var(--font-family-default);
    font-size: 12px;
    max-width: 80%;
  }
}
