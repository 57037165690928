@import "../Less/variables.css";

:root {
  --tvg-alert-font-size: 12px;
  --tvg-alert-spacer-width: 16px;
  --tvg-alert-color: var(--tvg-grey-dark);
  --tvg-alert-border: 2px solid;
}

.alertBox {
  font-size: var(--tvg-alert-font-size);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: var(--tvg-grey-dark);
  font-family: var(--font-family-default);
  box-sizing: border-box;
  padding: var(--tvg-alert-spacer-width);

  & a {
    color: var(--tvg-grey-dark);
    &:hover {
       color: inherit;
    }
    &:active {
       color: inherit;
    }
    &:visited {
       color: inherit;
    }
  }

  & strong {
    font-weight: bold;
  }
}

.alertBoxIcon {
  width: 32px;
  min-width: 32px;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  margin-right: var(--tvg-alert-spacer-width);

  & svg {
    width: 32px;
    height: 32px;
  }
}

.alertBoxContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.33;
  flex-grow: 1;
  min-width: 190px;
}

.alertBoxIconSVG {
  width: 32px;
  height: 32px;
}

.errorBox {
  background-color: color-mod(var(--tvg-red) l(+42.5%));
  border-bottom: var(--tvg-alert-border) var(--tvg-red);

  & .alertBoxIconSVG {
    fill: var(--tvg-red);
  }
}

.successBox {
  background-color: var(--color-mtp-far);
  border-bottom: var(--tvg-alert-border) var(--tvg-green);

  & .alertBoxIconSVG {
    fill: var(--tvg-green);
  }
}

.infoBox {
  background: var(--tvg-blue-lightest);
  border-bottom: var(--tvg-alert-border) var(--tvg-blue);

  & .alertBoxIconSVG {
    fill: var(--tvg-blue);
  }
}

.warningBox {
  background-color: color-mod(var(--tvg-yellow) l(+42.5%));
  border-bottom: var(--tvg-alert-border) var(--tvg-yellow);

  & .alertBoxIconSVG {
    fill: var(--tvg-yellow);
  }
}

.iconMessageContainer {
  display: inline-flex;
}

.closeButton {
  background: transparent;

  &:hover {
    background: transparent;
  }
}
