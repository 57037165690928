.loginModal {
  overflow: hidden;

  @media (max-width: 750px) {
    height: 100vh;
    width: 100vw;
    max-width: 100%;
  }

  &.customModal {
    @media (max-width: 750px) {
      height: auto;
    }
  }
}
