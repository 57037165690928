@import "@tvg/ui-bootstrap/components/Less/variables.css";
@import "../../variables.css";

.loginContainer {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;

  & > button {
    margin-right: 16px;
    height: 44px;
    text-transform: capitalize;
    font-family: var(--roboto-font-medium);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;

    @media (--xxl-view) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

.innerFlexWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & svg {
    width: 16px;
    height: 16px;
    overflow: hidden;
    margin: 0 3px 0 0;
    fill: var(--tvg-white);
  }
}

.activeButton {
  & button {
    background: rgb(255, 255, 255 / 0.1) !important;
  }

  & a svg {
    fill: var(--tvg-white) !important;
  }
}

.overlayArea {
  position: absolute;
  left: auto;
  right: -70px;
  top: 40px;
  height: 0;
  overflow: hidden;
  width: 272px;
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.1),
    0px 4px 6px rgba(0, 0, 0, 0.05);
  z-index: 10;
  background: var(--tvg-white);
  opacity: 0;
  border-radius: 4px;

  @media (--xxl-view) {
    left: 50%;
    right: auto;
    transform: translate(-50%, 0);
  }

  & > div {
    box-shadow:
      0px 4px 4px rgba(0, 0, 0, 0.1),
      0px 4px 6px rgba(0, 0, 0, 0.05);
  }
}

.overlayAreaIn {
  height: auto;
  overflow: visible;
  transition: all 0.4s cubic-bezier(0, 1, 0.6, 1);
  top: 48px;
  opacity: 1;
}
