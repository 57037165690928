/**
   *  TVG UI Variables
   *  In this file you can configure global variables used by all components.
   *  Some components have specific variables created, edit them on top of each component file.
   * if you add/change any colors please add it to the object in the pallete/index.json so we can have a pallete
   */

:root {
  /*
	* TVG Main colors + aliases
	*/
  --tvg-blue: #27609b;
  --tvg-red: #de3232;
  --tvg-green: #38ab50;
  --tvg-yellow: #ffb80c;
  --tvg-blue-whiteish: #f6f7fa;
  --tvg-blue-greyish: #e6e9ef;
  --tvg-blue-lighter: #cfdae2;
  --tvg-blue-lightest: #dfe7f0;
  --tvg-blue-midnight: #0f1f30;
  --tvg-blue-bright: #3e96ee;
  --tvg-blue-light: #93afcd;
  --tvg-blue-dark: #194879;
  --tvg-blue-darker: #253e58;
  --tvg-blue-darkest: #1c3247;
  --tvg-blue-ghost: #e3eaef;
  --tvg-green-light: #60bc73;
  --tvg-white: #fff;
  --tvg-white-ghost: rgba(255, 255, 255, 0.2);
  --tvg-grey: #666;
  --tvg-grey-light: #ccc;
  --tvg-grey-dark: #333;
  --tvg-black: #000;
  --tvg-grey-lightest: #f6f6f6;
  --tvg-grey-lighter: #ddd;
  --tvg-yellow-faded: #f8e3b2;
  --tvg-overlay: rgba(0, 0, 0, 0.7);
  --color-primary: var(--tvg-blue);
  --color-secondary: var(--tvg-yellow);
  --color-tertiary: var(--tvg-green);
  --color-alert: var(--tvg-red);
  --color-info: #4491e1;
  --color-white: var(--tvg-white);
  --color-text-title: var(--tvg-grey-dark);
  --color-text-body: var(--tvg-grey-dark);
  --color-mtp-far: color-mod(var(--tvg-green) l(+50%));
  --color-mtp-med: color-mod(var(--tvg-yellow) l(+42.5%));
  --color-mtp-near: color-mod(var(--tvg-red) l(+42.5%));

  /* repsonsive vars */
  @custom-media --phone-viewport (max-width: 480px);
  @custom-media --tablet-viewport (max-width: 768px);
  @custom-media --desktop-viewport (max-width: 1074px);

  /* Typography variables */
  --font-Univers-Condensed: 'UniversLT-Condensed';
  --font-Univers-CondensedItalic: 'UniversLTStd-CnObl';
  --font-Univers-CondensedLight: 'UniversLTStd-LightCn';
  --font-Univers-CondensedLightitalic: 'UniversLTStd-LightCnObl';
  --font-Univers-CondensedBold: 'UniversLT-CondensedBold';
  --font-Univers-CondensedBoldItalic: 'UniversLT-BoldCnObl';
  --font-family-default: 'Arial-MT', Arial, sans-serif;
  --font-family-titling:
    var(--font-Univers-CondensedBold),
    var(--font-family-default);
  --font-weight-bold: bold;
  --font-weight-normal: normal;
  --font-size-normal: 12px;
}
