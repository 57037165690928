@import "@tvg/ui-bootstrap/components/Less/variables.css";
@import "../../variables.css";

.contentCardsContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  margin: 0 6px;
}

.contentCardsActive {
  background: rgba(255, 255, 255, 0.1) !important;

  & a svg {
    fill: var(--tvg-white) !important;
  }

  &:hover {
    & .notificationsTooltip {
      visibility: hidden !important;
    }
  }
}
.contentCardsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: none;
  border: none;
  outline: none;
  border-radius: 50%;
  padding: 0;

  & span {
    width: 38px;
    height: 38px;

    &:active {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);

    & .notificationsTooltip {
      visibility: visible;
    }

    & a svg {
      fill: var(--tvg-white);
    }
  }

  & .notificationsTooltip {
    visibility: hidden;
    position: absolute;
    top: calc(var(--header-height) - 8px);
    z-index: 99;
    height: 30px;
    padding: 8px;
    color: var(--tvg-black);
    background: var(--tvg-white);
    box-shadow:
      0px 4px 4px rgba(0, 0, 0, 0.1),
      0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size-normal);
    line-height: 17px;
    overflow: hidden;
    white-space: nowrap;
  }
}

.overlayArea {
  position: absolute;
  left: 50%;
  top: calc(var(--header-height) - 6px);
  transform: translateX(-50%);
  height: 0;
  overflow: hidden;
  width: 320px;
  z-index: 10;
  background: var(--tvg-white);
  opacity: 0;
  border-radius: 4px;
  box-shadow:
    0 4px 4px rgba(0, 0, 0, 0.1),
    0 4px 6px rgba(0, 0, 0, 0.05) !important;

  & > :first-child {
    flex-direction: column;
  }

  @media (--xs-view) {
    transform: translate(-17%, 0);
  }
}

.contentCardsPanelHeader {
  margin-bottom: 1px;
  padding: 12px;
  font-size: 18px;
  font-family: var(--roboto-font-bold);
  box-shadow: 0px 1px 0px #e9eff5;
}

.renderContentCards {
  flex: 1;
  overflow: auto;
}

.overlayArea :global .ab-feed {
  width: 100%;
  border-radius: 0;
  background: none;
  box-shadow: none;
  transition: none !important;
}

.overlayArea :global .ab-feed .ab-feed-buttons-wrapper {
  display: none;
}

.overlayArea :global .ab-feed .ab-feed-body {
  padding: 0;
  border: 0;
}

.overlayArea :global .ab-feed .ab-card {
  margin-bottom: 1px;
  border: 0;
  border-radius: 0;
  box-shadow: 0px 1px 0px #e9eff5;
  z-index: 1001;
  position: relative;
}

.overlayArea :global .ab-feed .ab-card.ab-effect-card.ab-image-only.with-image {
  margin: 0;
}

.overlayArea :global .ab-feed .ab-card:hover {
  background-color: #fafcff;
}

.overlayArea :global .ab-feed .ab-card:active {
  background-color: #ebf5ff;
}

.overlayArea :global .ab-feed .ab-card:last-child {
  border: 0;
}

.overlayArea :global .ab-feed .ab-card:active .ab-unread-indicator.read,
.overlayArea :global .ab-feed .ab-card:active .ab-unread-indicator {
  background-color: transparent;
}

.overlayArea :global .ab-feed .ab-card a {
  position: relative;
  z-index: 1;
}

.overlayArea :global .ab-feed .ab-card .ab-image-area {
  position: relative;
  z-index: 1;
}

.overlayArea
  :global
  .ab-feed
  .ab-card.ab-classic-card.with-image
  .ab-image-area {
  width: 42px;
  height: 42px;
  padding: 18px 0 18px 12px;
  text-align: center;
}

.overlayArea
  :global
  .ab-feed
  .ab-card.ab-classic-card.with-image
  .ab-image-area
  img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.overlayArea :global .ab-feed .ab-card .ab-card-body {
  z-index: 1;
}

.overlayArea :global .ab-feed .ab-card.ab-classic-card .ab-card-body {
  min-height: 76px;
  z-index: 1;
}
.overlayArea
  :global
  .ab-feed
  .ab-card.ab-classic-card.with-image
  .ab-card-body {
  padding-left: 54px;
}

.overlayArea :global .ab-feed .ab-card .ab-title,
.overlayArea :global .ab-feed .ab-card.ab-classic-card.with-image .ab-title {
  padding: 18px 12px 0;
  color: var(--tvg-grey-dark);
  font-size: 14px;
  font-family: var(--font-family-default);
  font-weight: 700;
}

.overlayArea :global .ab-feed .ab-card .ab-description,
.overlayArea
  :global
  .ab-feed
  .ab-card.ab-classic-card.with-image
  .ab-description {
  padding: 4px 12px 18px;
  line-height: 18px;
  font-size: 14px;
  color: var(--tvg-grey);
}

.overlayArea :global .ab-feed .ab-card .ab-url-area {
  margin-top: 8px;
  /*font-family: ${fontNormal};*/
  font-size: 14px;
  /*color: var(--tvg-blueaccent-500);*/
}

.overlayArea :global .ab-feed .ab-card .ab-pinned-indicator {
  border: 0;
  width: 12px;
  height: 14px;
  top: 18px;
  right: 13px;
  z-index: 100;
}

.overlayArea :global .ab-feed .ab-card .ab-pinned-indicator .fa-star {
  color: var(--tvg-yellow);
  right: 0;
  font-size: 13px;
}

.overlayArea :global .ab-feed .ab-card .ab-unread-indicator {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 0;
  background-color: #fafcff;
}

.overlayArea :global .ab-feed .ab-card .ab-unread-indicator.read {
  background-color: var(--tvg-white);
  display: none;
}

.overlayArea :global .ab-feed .ab-captioned-image .ab-card-body .ab-title,
.overlayArea
  :global
  .ab-feed
  .ab-captioned-image
  .ab-card-body
  .ab-card.ab-classic-card.with-image
  .ab-title {
  color: var(--tvg-grey-dark);
}

.overlayArea :global .ab-feed .ab-no-cards-message {
  background-color: var(--tvg-white);
  display: none;
}

.overlayAreaIn {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: calc(100vh - 80px);
  transition: all 0.4s cubic-bezier(0, 1, 0.6, 1);
  opacity: 1;
}
