@import '@tvg/ui-bootstrap/components/Less/variables.css';

.loginRedirect {
  width: 100%;
  font-family: var(--font-family-default);
  font-size: 12px;
  text-align: center;

  & .header {
    padding: 8px 16px;
    text-align: center;
    font-family: var(--font-Univers-CondensedBold);
    font-size: 18px;
    text-transform: uppercase;
    background-color: var(--tvg-blue-dark);
    color: var(--tvg-white);
    line-height: 32px;
  }

  & .body {
    padding: 16px;
  }

  & .text,
  & .button {
    display: block;
    color: var(--tvg-grey-dark);
  }

  & .text {
    line-height: 1.33;
    padding: 24px 30px 40px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--tvg-grey-lighter);
  }

  & .button {
    text-transform: uppercase;
    background: var(--tvg-grey-lighter);
    line-height: 40px;
    border-radius: 2px;
    text-decoration: none;
    border: none;
    width: 100%;
    font-weight: bold;

    &:hover,
    &:active {
      background: var(--tvg-grey-light);
    }

    &:active {
      box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.25);
    }
  }
}
