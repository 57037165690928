@import '@tvg/ui-bootstrap/components/Less/variables.css';

.loginBlockHeader {
  padding: 16px 16px 0;

  & .loginBlockHeaderTitle {
    font-family: var(--font-Univers-Condensed);
    font-size: 20px;
    font-weight: bold;
    color: var(--tvg-blue);
    text-transform: uppercase;
  }
}
