@import '@tvg/ui-bootstrap/components/Less/variables.css';

.loginBlockFooter {
  padding: 16px 16px 0;
  background-color: var(--tvg-blue-whiteish);
  box-sizing: border-box;
  box-shadow: inset 0 1px 0 0 var(--tvg-grey-lighter);

  & .btnLoginFooter {
    height: 40px;
    font-size: 12px;

    /* really sorry for that important :( */
    background-color: var(--tvg-blue-bright) !important;
    display: inline-block;
  }

  & .loginBlockFooterLink {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  & .forgotCredentials {
    font-family: var(--font-family-default);
    font-size: 11px;
    color: var(--tvg-blue-bright);
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }
}

.loginBlockFooterMobile {
  background-color: var(--tvg-grey-lightest);
  height: 150px;
  text-align: center;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 0;

  & .loginBlockFooterLink {
    padding: 16px 0;
    display: block;
  }
}

@media screen and (max-width: 750px) and (orientation: landscape) {
  .loginBlockFooter .btnLoginFooter {
    order: 2;
    width: 304px;
  }

  .loginBlockFooterMobile {
    display: flex;
    justify-content: space-around;
    text-align: left;
    height: 112px;

    & .loginBlockFooterLink {
      width: 125px;
      padding: 0;
      order: 1;
    }
  }
}

.loginBlockFooterMobile {
  /* iPhone 5 */
  @media (device-width: 320px) {
    height: 170px;
  }

  /* iPhone 5 landscape */
  @media (device-width: 320px) and (orientation: landscape) {
    height: 103px;
  }

  /* iPhone 6 */
  @media (width: 375px) {
    height: 170px;
  }

  /* iPhone 6 */
  @media (device-width: 375px) and (orientation: landscape) {
    height: 56px;
  }

  /* iPhone 6 Plus */
  @media (width: 414px) {
    height: 170px;
  }

  /* iPhone 6 Plus */
  @media (device-width: 414px) and (orientation: landscape) {
    height: 56px;
  }

  /* iPhone X */
  @media (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    height: 210px;
  }

  /* iPhone X landscape */
  @media (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    height: 56px;
  }
}
