@import '@tvg/ui-bootstrap/components/Less/variables.css';

.signupHeader {
  padding: 16px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  z-index: 6;

  @media screen and (max-width: 750px) and (orientation: landscape) {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 568px) and (orientation: landscape) {
    height: 20px;
  }

  & .signupHeaderTitle {
    font-family: var(--font-Univers-Condensed);
    font-size: 20px;
    font-weight: bold;
    color: var(--tvg-green);
    padding-bottom: 4px;
    text-transform: uppercase;
  }

  & .firstTextLine,
  & .secondTextLine {
    font-family: var(--font-family-default), sans-serif;
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 4px;
    color: var(--tvg-white);
    text-transform: uppercase;
  }
}
