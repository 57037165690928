@import '@tvg/ui-bootstrap/components/Less/variables.css';

.container {
  margin: 16px 0;
}

.label {
  font-family: var(--font-family-default);
  font-size: 12px;
  height: 12px;
  margin-bottom: 4px;
  font-weight: bold;
  color: var(--tvg-grey-dark);
  display: block;
  position: relative;
}

.statusIcon {
  width: 16px;
  height: 16px;
  position: absolute;
  margin: -2px 0 0 3px;
}

.warningIcon {
  fill: var(--tvg-red);
}

.OkIcon {
  fill: var(--tvg-green);
}

.inputSelectWrapper {
  position: relative;
}

.inputSelect {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: 1px solid var(--tvg-grey-light);
  appearance: none;
  font-weight: normal;
  position: relative;
  z-index: 2;
  padding-left: 8px;
  border-radius: 2px;
  font-family: var(--font-family-default);
  font-size: 12px;
  color: var(--tvg-grey);

  &:active,
  &:focus {
    outline: none;
  }

  &:focus {
    border: solid 1px var(--tvg-blue-bright);
  }

  &::-ms-expand {
    display: none;
  }
}

.inputSelectInvalid {
  box-shadow: inset 0 -2px 0 0 var(--tvg-red);
}

.inputSelectArrow {
  fill: var(--tvg-grey);
  width: 16px;
  height: 40px;
  position: absolute;
  z-index: 1;
  color: var(--tvg-grey-dark);
  right: 16px;
  top: 0;
}

.warningMessage {
  font-size: 12px;
  color: var(--tvg-red);
  margin-top: 4px;
}

.disclaimer {
  margin-top: 16px;
  color: var(--tvg-grey-dark);
  font-size: 12px;
  line-height: 1.33;
}
