@import "@tvg/ui-bootstrap/components/Less/variables.css";
@import "../../../variables.css";

.overlayArea {
  cursor: auto;
  z-index: 10;
  position: absolute;
  top: 50px;
  background: var(--tvg-white);
  border: 0;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
  width: 284px;
  font-size: 12px;
  left: 50%;
  transform: translate(-50%, 0);

  & > section {
    border-radius: 4px;
  }

  & .scroll {
    max-height: calc(95vh - 256px - 68px);
    overflow: auto;
  }
}

.overlayArea.accountPanelV2 {
  width: 375px;
  border-radius: 4px;
}

.links {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  background: var(--tvg-grey-lightest);
  border-top: 1px solid var(--tvg-grey-lighter);
  padding-top: 1px;

  & > :nth-child(3n + 1) {
    box-shadow: inset 0 1px 0 0 var(--tvg-blue-lightest);
  }

  & > :last-child {
    box-shadow: inset 0 1px 0 0 var(--tvg-blue-lightest);
  }

  & > :first-child {
    box-shadow: none;
  }

  & a {
    color: var(--tvg-blue);
    padding: 14px 24px;
    display: block;

    &:hover {
      color: var(--tvg-blue-bright);
    }
  }
}
