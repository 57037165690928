@import "../Less/variables.css";

:root {
  --tvg-button-padding-big: 11px 16px;
  --tvg-button-padding-small: 8px;
  --tvg-button-border-width: 1px;
  --tvg-button-border-radius: 2px;
  --tvg-button-height-small: 32px;
  --tvg-button-height-big: 40px;
  --tvg-button-text-spacing: 0.7px;
  --tvg-button-text-weight: var(--font-weight-bold);
  --tvg-button-text-size: var(--font-size-normal);
  --tvg-button-text-size-small: 11px;
  --tvg-button-text-size-big: 12px;
  --tvg-button-shadow: inset 0 0 0 1px var(--tvg-grey-light);
}

[class^="tvg-btn"] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
  padding: var(--tvg-button-padding-small);
  font-size: var(--tvg-button-text-size-small);
  font-weight: var(--tvg-button-text-weight);
  border-radius: var(--tvg-button-border-radius);
  line-height: 1.5;
  outline: none;
  border: none;
  transition: all 100ms ease;
  height: var(--tvg-button-height-small);
  display: inline-block; /* Safari fix */

  &[disabled] {
    opacity: 0.3;
    cursor: initial;
  }

  &.selected {
    background-color: var(--tvg-white);
    color: var(--tvg-blue);

    &:hover {
      background: color-mod(var(--tvg-white) b(+2%));
    }
  }

  &.full-width {
    width: 100%;
  }

  &.big {
    padding: var(--tvg-button-padding-big);
    font-size: var(--tvg-button-text-size-big);
    height: var(--tvg-button-height-big);
    line-height: 1.6;
  }
}

.tvg-btn-primary {
  background: var(--tvg-green);
  color: var(--tvg-white);

  &:hover {
    background: color-mod(var(--tvg-green) l(+10%));
  }

  &:active,
  &:focus {
    background: color-mod(var(--tvg-green) l(-10%));
  }
}

.tvg-btn-secondary {
  background: var(--tvg-grey-lighter);
  color: var(--tvg-grey-dark);

  &:hover {
    background: color-mod(var(--tvg-grey-light) l(+10%));
  }

  &:active,
  &:focus {
    background: color-mod(var(--tvg-grey-light) l(-10%));
  }
}

.tvg-btn-marketing {
  background: var(--tvg-yellow);
  color: var(--tvg-grey-dark);

  &:hover {
    background: color-mod(var(--tvg-yellow) l(+10%));
  }

  &:active,
  &:focus {
    background: color-mod(var(--tvg-yellow) l(-10%));
  }
}

.tvg-btn-secondary-alt {
  background: var(--tvg-white-ghost);
  color: var(--tvg-white);

  &:hover {
    background: color-mod(var(--tvg-white-ghost) a(+10%));
  }

  &:active,
  &:focus {
    background: color-mod(var(--tvg-white-ghost) a(+10%));
  }
}

.tvg-btn-tertiary {
  background: var(--tvg-white);
  box-shadow: var(--tvg-button-shadow);
  color: var(--tvg-grey-dark);

  &:hover {
    background: color-mod(var(--tvg-white) l(-10%));
  }

  &:active,
  &:focus {
    background: color-mod(var(--tvg-white) l(-20%));
  }
}

.tvg-btn-legacy {
  background: var(--tvg-blue-bright);
  color: var(--tvg-white);

  &:hover {
    background: color-mod(var(--tvg-blue-bright) l(-10%));
  }

  &:active,
  &:focus {
    background: color-mod(var(--tvg-blue-bright) l(-20%));
  }
}

.inline-button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  font-size: 12px;
  font-family: var(--font-family-default);
  color: var(--tvg-blue-bright);
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.tvg-btn,
[class^="tvg-btn"] {
  &.icon {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;

    &.iconRight svg {
      margin-left: 4px;
      margin-right: 0;
    }

    & svg {
      margin-right: 4px;
      margin-left: 0;
    }
  }
}
