@import '@tvg/ui-bootstrap/components/Less/variables.css';

.loginSignupHeader {
  background-color: var(--tvg-blue-dark);
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .loginSignupHeaderLogo {
    margin-left: 16px;
    width: 130px;
    height: 30px;
  }

  & .baseLogo,
  & .njLogo,
  & .paLogo,
  & .iaLogo {
    background-size: contain;
    background-repeat: no-repeat;
  }

  & .njLogo {
    height: 27px;
    background-image: url('@tvg/ui-bootstrap/assets/images/4njbets-logo-hor-white.svg');
  }

  & .paLogo {
    background-image: url('@tvg/ui-bootstrap/assets/images/pabets-logo-hor-white.svg');
  }

  & .iaLogo {
    background-image: url('@tvg/ui-bootstrap/assets/images/tvgia-logo-hor-white.svg');
  }

  & .baseLogo {
    margin-top: 8px;
    background-image: url('@tvg/ui-bootstrap/assets/images/tvg-logo-hor-white.svg');
  }

  & .loginSignupHeaderCloseBlock {
    margin-right: 16px;
  }

  & .loginSignupHeaderCloseIcon {
    width: 16px;
    height: 16px;
    fill: var(--tvg-white);
    cursor: pointer;
  }
}

.closeIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: transparent;

    &:hover {
       cursor: pointer;
     }

    &:active,
    &:focus {
     outline: none;
   }
}
