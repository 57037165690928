@import '@tvg/ui-bootstrap/components/Less/variables.css';

.tabSelectorContainer {
  height: 40px;
  width: 100%;
  display: flex;

  & .tabSelector {
    height: 40px;
    background-color: var(--tvg-grey-lightest);
    font-weight: bold;
    font-family: var(--font-family-default);
    font-size: 12px;
    box-shadow: inset 1px 0 0 0 var(--tvg-grey-lighter), inset 0 -1px 0 0 var(--tvg-grey-lighter);
    text-transform: uppercase;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    & .btnTab {
      text-decoration: none;
      color: var(--tvg-grey);
      width: 100%;
      height: 100%;
      text-transform: uppercase;
      font-weight: bold;
      font-family: var(--font-family-default);
      font-size: 12px;
      background-color: transparent;
      border: none;
      outline: none;

      &:hover {
        cursor: pointer;
      }
    }

    &.activeTab {
      background-color: var(--tvg-white);
      box-shadow: inset 1px 0 0 0 var(--tvg-blue-lighter), inset 0 3px 0 0 var(--tvg-blue-bright);

      & button {
        color: var(--tvg-grey-dark);
      }
    }
  }
}
