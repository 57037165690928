@import '@tvg/ui-bootstrap/components/Less/variables.css';

.container {
  margin: 16px 0;
}

.label {
  font-family: var(--font-family-default);
  font-size: 12px;
  height: 12px;
  margin-bottom: 4px;
  font-weight: bold;
  color: var(--tvg-grey-dark);
  display: block;
  position: relative;
}

.statusIcon {
  width: 16px;
  height: 16px;
  position: absolute;
  margin: -2px 0 0 3px;
}

.warningIcon {
  fill: var(--tvg-red);
}

.OkIcon {
  fill: var(--tvg-green);
}

.inputWrapper {
  position: relative;
}

.inputText {
  height: 40px;
  width: 100%;
  font-size: 12px;
  padding-left: 0;
  font-weight: normal;
  border-radius: 2px;
  border: 1px solid var(--tvg-grey-light);
  text-indent: 8px;
  box-sizing: border-box;

  &:focus {
    border: solid 1px var(--tvg-blue-bright);
    outline: none;
  }

  &:-ms-input-placeholder {
    font-family: var(--font-family-default);
    font-size: 12px;
    color: var(--tvg-grey);
  }
}

.inputTextInvalid {
  box-shadow: inset 0 -2px 0 0 var(--tvg-red);
}

.warningMessage {
  font-family: var(--font-family-default);
  font-size: 12px;
  color: var(--tvg-red);
  margin-top: 4px;
}
