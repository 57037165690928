@import '@tvg/ui-bootstrap/components/Less/variables.css';

.signupBlockFooter {
  padding: 22px 16px 40px;
  height: 121px;
  text-align: center;
  position: relative;
  z-index: 6;

  @media screen and (max-width: 750px) and (orientation: landscape) {
    height: 82px;
    padding-top: 0;
  }

  & .btnSignupFooter {
    height: 40px;
    display: inline-block;

    @media screen and (max-width: 750px) and (orientation: landscape) {
      width: 304px;
    }
  }
}

.signupBlockFooterMobile {
  padding: 3px 8px 0;

  /* iPhone 5 */
  @media (device-width: 320px) {
    height: 127px;
  }

  /* iPhone 5 landscape */
  @media (device-width: 320px) and (orientation: landscape) {
    height: 93px;
  }

  /* iPhone 6 */
  @media (device-width: 375px) {
    height: 126px;
  }

  /* iPhone 6 Plus */
  @media (device-width: 414px) {
    height: 126px;
  }

  /* iPhone 6 Plus landscape */
  @media (device-width: 414px) and (orientation: landscape) {
    height: 82px;
    padding-top: 33px;
  }

  /* iPhone X */
  @media (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    height: 165px;
    padding-top: 3px;
  }

  /* iPhone X landscape */
  @media (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    height: 60px;
    padding-top: 8px;
    min-height: auto;
  }

  /* iPhone 8 */
  @media (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
    height: 82px;
    min-height: auto;
    padding-top: 33px;
  }
}
