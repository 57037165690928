@import "@tvg/ui-bootstrap/components/Less/variables.css";
@import "../../../variables.css";

.logoutSection {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 16px;
  height: 58px;
  background-color: var(--tvg-white);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 0 4px 4px;
  position: sticky;
  bottom: 0;

  & .logoutButton {
    line-height: 1;
    padding: 0px 16px;
  }

  & a {
    color: var(--tvg-blue-bright);

    &:hover {
      text-decoration: underline;
    }
  }
}

.logoutSection.centered {
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 16px;
  padding-top: 16px;
  border-top: 0;
  box-shadow: inset 0px 1px 0px #d6e3f0;

  & button {
    width: 100%;
    color: #de3131;
  }
}
