@import "@tvg/ui-bootstrap/components/Less/variables.css";
@import "../../variables.css";

.signupBtn,
.signupBtn[class^="tvg-btn"] {
  @media (--xxl-view) {
    padding-left: 12px;
    padding-right: 12px;
  }
  height: 44px;
  text-transform: capitalize;
  font-family: var(--roboto-font-medium);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
}

.innerFlexWrapper {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  justify-content: space-between;

  & svg {
    width: 16px;
    height: 16px;
    overflow: hidden;
    fill: var(--tvg-white);
  }
}

.buttonSection {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: transparent;
  margin-left: auto;

  & .depositButton {
    margin-right: 8px;
    width: 125px;
  }

  & .quickDepositLabel {
    font-size: 8px;
    display: block;
    margin-bottom: 2px;
    height: 9px;
  }

  & .depositLabel {
    opacity: 1;
    animation: fadeIn 0.3s ease-in-out 0s;
  }

  & .balanceContainer {
    display: flex;
    flex-direction: column-reverse;
    margin-top: -6px;
  }

  & .depositLabelNum {
    display: block;
    margin-top: -4px;
    opacity: 1;
    animation: fadeIn 0.3s ease-in-out 0s;
    margin-right: 4px;
    letter-spacing: 0.9px;
    font-family: var(--font-Univers-CondensedBold);
  }

  & .depositLabelText {
    font-size: 11px;
  }

  & .depositLabelVisible {
    font-family: var(--font-Univers-Condensed);
    font-size: 14px;
  }

  & .depositLabelInvisible {
    display: none;
    opacity: 0;
    animation: fadeOut 0.3s ease-in-out 0s;
  }
}

.balanceButton {
  margin: 0px;
}

.newBalanceButton {
  margin-right: 16px;
  margin-left: 16px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .buttonSection {
    padding: 0 20px 0 0;
  }
}
