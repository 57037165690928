@import "@tvg/ui-bootstrap/components/Less/variables.css";
@import "../../variables.css";

:root {
  --subMenuWidth: 375px;
  --subMenuHeight: auto;
}

@media (--xs-view), (--s-view), (--m-view), (--l-view) {
  .subMenuList {
    width: auto;
    height: var(--subMenuHeight);
    border: none;
    white-space: normal;

    & > li {
      min-height: 12px !important;
      padding: 0;
      border: none;
      background-color: transparent !important;

      & svg {
        width: 16px;
        height: 16px;
        fill: #d6e3f0;
      }

      & h3 {
        font-size: 14px;
        font-weight: normal;
        color: #d6e3f0;
        text-transform: none;
        white-space: nowrap;
        font-family: var(--roboto-font-medium);

        &:hover {
          background-color: transparent;
        }
      }

      & > a {
        display: flex !important;
        margin: 0 !important;
        padding: 12px;
        align-items: center;

        & div {
          &:hover {
            background-color: transparent;
          }
        }

        & > div:not(first-child) > div {
          margin-bottom: 0;
        }

        & > div:not(first-child) > div > div {
          display: none;
        }

        & span {
          font-family: var(--roboto-title-font) !important;
          text-transform: none;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
        }

        & > div:not(first-child) > span {
          display: none !important;
          line-height: 16px;
          vertical-align: center;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 4px;

          & h3 {
            color: var(--tvg-white);
          }

          & svg {
            fill: var(--tvg-white);
          }
        }

        &:active {
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 4px;
        }
      }
    }

    & > li:last-child,
    & > li:last-child > a {
      margin-bottom: 0 !important;
    }
  }
}

@media (--xxl-view), (--xl-view) {
  .subMenuList {
    width: var(--subMenuWidth);
    height: var(--subMenuHeight);
    background-color: var(--tvg-white);
    box-shadow:
      0 4px 4px rgba(0, 0, 0, 0.1),
      0 4px 6px rgba(0, 0, 0, 0.05) !important;
    border-radius: 4px;

    & > li {
      border: none;

      &:first-child {
        border-radius: 4px 4px 0 0 !important;
      }

      &:last-child {
        border-radius: 0 0 4px 4px !important;
      }

      & h3 {
        color: #384048;
        text-transform: none;
        font-family: var(--roboto-font-medium);
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
      }

      & span {
        color: #818a92;
        text-transform: none;
      }

      & svg {
        margin-top: 6px;
      }
    }
  }
}
