@import "@tvg/ui-bootstrap/components/Less/variables.css";
@import "../../variables.css";

:root {
  --subMenuWidth: auto;
  --subMenuHeight: auto;
  --triangleBorderHeight: 10px;
  --triangleBorderWidth: 15px;
  --triangleBorderWidthSplit: (var(--triangleBorderWidth) / 2);
}

.subMenuList {
  & .newSubMenuLabel {
    background-color: var(--tvg-yellow);
    margin-left: 8px;
    border-radius: 2px;
    color: var(--tvg-grey-dark);
    padding: 0 2px;
    font-size: 10px;
    vertical-align: text-top;
  }
}

@media (--xxl-view), (--xl-view) {
  .subMenuList {
    width: var(--subMenuWidth);
    height: var(--subMenuHeight);
    background-color: var(--tvg-white);
    font-size: 12px;
    font-weight: bold;
    border-radius: 4px;

    & > .subMenuItem {
      &:first-child {
        & .subMenuLink {
          margin-top: 23px;
        }
      }

      &:last-child {
        & .subMenuLink {
          margin-bottom: 23px;
        }
      }

      & .subMenuLink {
        color: var(--tvg-blue);
        display: block;
        margin-bottom: 30px;
        padding: 0 24px;

        &:hover {
          color: var(--tvg-blue-bright);
        }

        &:focus,
        &:active {
          outline: none;
        }
      }
    }
  }
}
