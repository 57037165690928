@import "fonts.css";

:root {
  --tablet: only screen and (max-width: 968px);
  --desktop: only screen and (min-width: 969px);
  --desktopWithoutLHN: only screen and (max-width: 1224px);
  --bp-xs: 590px;
  --bp-s: 758px;
  --bp-m: 968px;
  --bp-l: 1000px;
  --bp-xl: 1175px;
  --header-height: 68px;
  --menu-top-padding: 20px;
  --side-menu-width: 253px;
  --link-color: #295f97;
  --text-grey-black: #333333;
  --tvg-white-hdr-btn: rgba(255, 255, 255, 0.1);
  --roboto-font-regular: "Roboto-Regular";
  --roboto-font-medium: "Roboto-Medium";
  --roboto-font-bold: "Roboto-Bold";
  --roboto-font-black: "Roboto-Black";
  --roboto-title-font: "Roboto Condensed";
}

@custom-media --xs-view (max-width: 757px);
@custom-media --xxl-view (min-width: 1256px);
@custom-media --s-view (min-width: 758px) and (max-width: 967px);
@custom-media --m-view (min-width: 968px) and (max-width: 1060px);
@custom-media --l-view (min-width: 1061px) and (max-width: 1190px);
@custom-media --xl-view (min-width: 1191px) and (max-width: 1255px);
@custom-media --small-view (max-width: 757px);
@custom-media --extra-large-view (min-width: 1256px);
@custom-media --small-medium-view (min-width: 758px) and (max-width: 967px);
@custom-media --small-medium-view (min-width: 968px) and (max-width: 1014px);
@custom-media --medium-large-view (min-width: 1015px) and (max-width: 1190px);
@custom-media --large-extra-large-view (min-width: 1191px) and (max-width: 1255px);
