@import "@tvg/ui-bootstrap/components/Less/variables.css";
@import "../../../variables.css";

button.betslipButton {
  padding: 16px;
  display: flex;
  height: 44px;
  text-transform: none;

  & .innerFlexWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & svg {
      width: 16px;
      height: 16px;
      overflow: hidden;
    }

    & .betButtonLabel {
      margin-left: 8px;
      color: #384048;
      font-family: var(--roboto-font-medium);
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
  }
}

button.betslipButtonV2 {
  padding: 16px;
  display: flex;
  height: 44px;

  & .innerFlexWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & .betButtonLabel {
      margin-left: 8px;
      color: #384048;
      font-family: var(--roboto-font-medium);
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      text-transform: none;
    }
  }
}
