@import "../Less/variables.css";

:root {
  --small-loading: 28px;
  --normal-loading: 24px;
  --big-loading: 40px;
}

@keyframes rotate {
  100% {
    transform: rotateZ(360deg) translate(-50%, -50%);
  }
}

.loading {
  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
  }

  &::before {
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: block;
    background-image: url("data:image/svg+xml,<svg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2040%2040%27><g%20fill-rule%3D%27evenodd%27><path%20fill%3D%27%23fff%27%20d%3D%27M20%2036c-7.73%200-14-6.27-14-14S12.27%208%2020%208s14%206.27%2014%2014-6.27%2014-14%2014zm0-2.63c6.28%200%2011.38-5.1%2011.38-11.37%200-6.28-5.1-11.38-11.38-11.38-6.28%200-11.38%205.1-11.38%2011.38%200%206.28%205.1%2011.38%2011.38%2011.38zM17%203h6l-1%204.5h-4L17%203zm10.27%202.4l3.46%202-1%201.74-3.46-2%201-1.73zm-18%202l3.46-2%201%201.74-3.46%202-1-1.73z%27%2F><%2Fg><%2Fsvg>");
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: var(--tvg-overlay);
  }

  &::after {
    transform: rotateZ(0) translate(-50%, -50%);
    transform-origin: 0% 5%;
    animation: rotate 1s infinite linear;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cpath fill='%23fff' d='M20 24c1.1 0 2-.9 2-2s-2-10-2-10-2 8.9-2 10c0 1.1.9 2 2 2z'/%3E%3C/g%3E%3C/svg%3E");
  }
}

.loadingSmall {
  &::before {
    background-size: var(--small-loading);
  }

  &::after {
    width: var(--small-loading);
    height: var(--small-loading);
  }
}

.loadingMedium {
  &::before {
    background-size: var(--normal-loading);
  }

  &::after {
    width: var(--normal-loading);
    height: var(--normal-loading);
  }
}

.loadingBig {
  &::before {
    background-size: var(--big-loading);
  }

  &::after {
    width: var(--big-loading);
    height: var(--big-loading);
  }
}

/* Fix styleguide */
div[data-preview="Loading"] {
  position: relative;
}
