@import '@tvg/ui-bootstrap/components/Less/variables.css';

.loginBlock {
  width: 403px;
  background-color: var(--tvg-white);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;

  @media (max-width: 880px) {
    width: 50%;
  }

  & .formStyle {
    padding: 0 16px;
  }

  & .mobileFormStyle {
    height: 100%;
    padding: 0 8px;
    overflow: scroll;
  }
}

.loginBlockMobile {
  background-color: var(--tvg-white);
  width: 100%;
  height: 100%;
}

input::-webkit-input-placeholder,
input::-moz-placeholder,
input:-moz-placeholder,
input:-ms-input-placeholder {
  font-family: var(--font-family-default);
  font-size: 12px;
  color: var(--tvg-grey);
}

.loginBlockDropdown {
  width: 100%;
}
