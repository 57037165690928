@import "@tvg/ui-bootstrap/components/Less/variables.css";
@import "../../main.css";

@media (--s-view), (--xs-view) {
  .tvgLogo > a {
    margin: 0;
  }
}

.header {
  background-color: #1e4b79;
  display: flex;
  align-items: flex-start;
  justify-content: initial;
  flex-direction: row;
  margin: auto;
  font-family: var(--font-family-default);
  height: var(--header-height);
  max-width: 1256px;

  & .tvgLogoContact,
  & .tvgLogo {
    min-width: 143px;
    align-items: initial;
    justify-content: initial;
    flex-direction: row;
    height: 100%;
    display: flex;

    & > a,
    & > a > svg {
      width: 128px;
      margin: 0 auto;
      height: var(--header-height);
      background-size: 100%;
      display: flex;
      align-items: initial;
      justify-content: initial;
      flex-direction: row;
    }
  }

  & .tvgLogoContact {
    position: absolute;
    left: 0;
    right: 0;
  }

  & .njLogo {
    background: 50% 50%
      url("@tvg/ui-bootstrap/assets/images/4njbets-logo-hor-white.svg")
      no-repeat;
  }

  & .paLogo {
    background: 50% 50%
      url("@tvg/ui-bootstrap/assets/images/pabets-logo-hor-white.svg") no-repeat;
  }

  & .iaLogo {
    background: 50% 50%
      url("@tvg/ui-bootstrap/assets/images/tvgia-logo-hor-white.svg") no-repeat;
  }

  & .baseLogo {
    background: 50% 50%
      url("@tvg/ui-bootstrap/assets/images/tvg-logo-white-powered-fanduel.svg")
      no-repeat;
  }
}

.identifyText {
  align-self: center;
  color: var(--tvg-white);
  padding: 0 8px;
}

.hamburgerBtn {
  margin: 0 24px;
  align-self: center;
  padding: 0;
  background: none;
  border: none;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  position: relative;
  width: 38px;
  min-width: 38px;
  height: 38px;
  outline: none;
  border-radius: 50%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.hamburgerBtn > span {
  background-color: #d6e3f0;
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 4px;
  position: absolute;
  transition: 0.25s ease-in-out;
  transform: rotate(0deg);
  opacity: 1;
  left: 8px;
  top: 12px;
}

.hamburgerBtn > span:nth-child(2) {
  top: 18px;
}

.hamburgerBtn > span:last-child {
  top: 24px;
}

.hamburgerBtn.crossed > span:first-child {
  transform: rotate(135deg);
  top: 18px;
}

.hamburgerBtn.crossed > span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.hamburgerBtn.crossed > span:last-child {
  transform: rotate(-135deg);
  top: 18px;
}

.hamburgerBtnBackground {
  background-color: rgba(255, 255, 255, 0.1);

  & span {
    background-color: var(--tvg-white);
  }
}

@media (--xxl-view) {
  .hamburgerBtn {
    display: none;
  }
}

@media (--xs-view), (--s-view), (--m-view), (--l-view), (--xl-view) {
  .tvgLogoStandard {
    width: 220px;
  }

  .headerShadow {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 10px;
      bottom: 0;
      box-shadow:
        0px 4px 4px rgba(0, 0, 0, 0.1),
        0px 4px 6px rgba(0, 0, 0, 0.05);
      z-index: 10;
    }
  }
}

@custom-media --member-reg (max-width: 780px);
@media (--member-reg), (--xs-view) {
  .identifyText {
    display: none;
  }
}
