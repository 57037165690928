.loginWrapperOther {
  width: 704px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;

  @media (max-width: 880px) {
    width: 550px;
  }
}

.loginWrapperMobile {
  width: 320px;
  height: 100%;
  max-height: 568px;

  @media (max-width: 750px) {
    width: 100%;
    max-height: 100%;
  }
}

.signupWrapper {
  width: 300px;
  display: flex;

  @media (max-width: 880px) {
    width: 50%;
  }

  @media (max-width: 700px) {
    height: calc(100vh - 88px);
  }
}

.signupWrapperMobile {
  width: 100%;
  height: 468px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  @media (max-width: 1024px) {
    height: calc(100% - 88px);
  }
}

.loginTestWrapper {
  position: absolute;
  width: 272px;
}

