@import "@tvg/ui-bootstrap/components/Less/variables.css";
@import "../../variables.css";

.tvgContactSection {
  display: flex;
  align-items: center;
  justify-content: initial;
  flex-direction: row;
  height: 48px;
  font-size: 12px;
  white-space: nowrap;
  color: var(--tvg-white);
  margin-left: auto;
  box-sizing: border-box;
  vertical-align: baseline;
  z-index: 2;
}

@media (--xs-view) {
  .tvgContactSection {
    padding-right: 30px;
  }
  .contactSubWrapper {
    flex-direction: column;
    width: 300px;

    & .tvgPhoneContact,
    & .tvgMailContact {
      text-decoration: none;
      width: 100%;
      justify-content: initial;
      line-height: 24px;
    }
  }
}

@media (--s-view) {
  .tvgContactSection {
    padding-right: 30px;
  }
  .contactSubWrapper {
    flex-direction: column;
    width: 300px;

    & .tvgPhoneContact,
    & .tvgMailContact {
      text-decoration: none;
      width: 100%;
      justify-content: initial;
      line-height: 24px;
    }
  }
}

@media (--m-view) {
  & .contactSubWrapper {
    flex-direction: column;
    width: 300px;

    & .tvgPhoneContact,
    & .tvgMailContact {
      text-decoration: none;
      width: 100%;
      justify-content: initial;
      line-height: 24px;
    }
  }
}

.contactSubWrapper {
  display: flex;
  align-items: center;
  justify-content: initial;
  flex-direction: row;
}

.contactSubWrapper a.tvgPhoneContact {
  display: flex;
  align-items: center;
  justify-content: initial;
  flex-direction: row;
  padding: 0 15px;
  color: var(--tvg-white);
  font-weight: normal;

  & svg {
    margin-right: 10px;
  }

  &:hover,
  &:focus {
    color: var(--tvg-white);
  }
}

.contactSubWrapper a.tvgMailContact {
  display: flex;
  align-items: center;
  justify-content: initial;
  flex-direction: row;
  padding-right: 15px;
  color: var(--tvg-white);
  font-weight: normal;

  & svg {
    margin-right: 10px;
  }

  &:hover,
  &:focus {
    color: var(--tvg-white);
  }
}

.tvgCostumerLabel {
  font-size: 14px;
  font-weight: 700;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: initial;
  flex-direction: row;
  position: relative;
  background: var(--tvg-blue);
  padding: 0 10px;

  &:before {
    display: block;
    margin-top: -8px;
    position: absolute;
    left: 100%;
    top: 50%;
    content: "";
    width: 0;
    height: 0;
    -moz-transform: scale(0.9999);
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    border-left: 8px solid var(--tvg-blue);
  }
}

.tvgContactIconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 6px;
  margin-right: 20px;
  height: var(--header-height);
  cursor: pointer;

  & > .tvgContactIcon {
    display: flex;
    align-items: center;
    padding: 5px 0;
    background-color: transparent;
    border: none;
    outline: none;
    position: relative;
    height: 100%;
    width: 100%;

    & span {
      visibility: hidden;
      position: absolute;
      top: calc(var(--header-height) - 8px);
      z-index: 99;
      height: 30px;
      padding: 8px;
      color: var(--tvg-black);
      background: var(--tvg-white);
      box-shadow:
        0px 4px 4px rgba(0, 0, 0, 0.1),
        0px 4px 6px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size-normal);
      line-height: 17px;
      overflow: hidden;
      white-space: nowrap;
    }

    & svg {
      overflow: inherit;
    }

    & i {
      border-radius: 50%;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      &:hover,
      &:focus {
        background-color: rgba(255, 255, 255, 0.1);

        & span {
          visibility: visible;
        }

        & svg {
          fill: var(--tvg-white);
        }
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.questionIcon {
  margin-right: 2px;
}
