@import '@tvg/ui-bootstrap/components/Less/variables.css';

.container {
  margin: 16px 0;
}

.label {
  font-family: var(--font-family-default);
  font-size: 12px;
  height: 12px;
  margin-bottom: 4px;
  font-weight: bold;
  color: var(--tvg-grey-dark);
  display: block;
  position: relative;
}

.statusIcon {
  width: 16px;
  height: 16px;
  position: absolute;
  margin: -2px 0 0 3px;
}

.warningIcon {
  fill: var(--tvg-red);
}

.OkIcon {
  fill: var(--tvg-green);
}

.inputWraper {
  display: flex;
  height: 40px;
  align-items: stretch;
  position: relative;
}

.inputPassword {
  flex-grow: 1;
  text-indent: 8px;
  font-size: 12px;
  padding-right: 78px;
  padding-left: 0;
  font-weight: normal;
  border-radius: 2px;
  width: 100%;
  border: 1px solid var(--tvg-grey-light);
  background: none;

  &:focus {
    border: solid 1px var(--tvg-blue-bright);
    outline: none;
  }

  &:-ms-input-placeholder {
    font-family: var(--font-family-default);
    font-size: 12px;
    color: var(--tvg-grey);
  }
}

.inputPasswordInvalid {
  box-shadow: inset 0 -2px 0 0 var(--tvg-red);
}

.buttonShow {
  font-family: var(--font-family-default);
  font-size: 11px;
  font-weight: bold;
  color: var(--tvg-blue-bright);
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  outline: none;
  width: 64px;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    background-color: var(--tvg-grey-lighter);
    top: 5px;
    bottom: 5px;
    left: 0;
    width: 1px;
  }
}

.warningMessage {
  font-family: var(--font-family-default);
  font-size: 12px;
  color: var(--tvg-red);
  margin-top: 4px;
}
