@import "@tvg/ui-bootstrap/components/Less/variables.css";
@import "./variables.css";

:root {
  --header-animation-speed: 0.25s;
  --header-animation: ease-in-out;
}

#tvg-header {
  display: block;
  width: 100%;
  height: 50px;
}

.tvgHeaderBase {
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.333;
  color: var(--tvg-grey-dark);
  padding: 0;
  margin: 0;
  background-color: #1e4b79;
  height: var(--header-height);

  & * {
    box-sizing: border-box;
    vertical-align: baseline;
  }

  & h1,
  & h2,
  & h3 {
    margin: 0;
  }

  & a,
  & a:hover,
  & a:focus {
    text-decoration: none;
  }

  & ul,
  & ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  & button,
  input[type="checkbox"] {
    outline: none;
  }

  & a,
  & button,
  & select,
  & input[type="submit"],
  & input[type="checkbox"] {
    cursor: pointer;
  }
}

@media (max-width: 1255px) {
  .tvgHeaderBase {
    z-index: 998;
    padding-right: 8px;
  }
}
